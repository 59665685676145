import { defineStore } from "pinia";
import { useStoreModule } from "./stores";
import type { AnybillResult } from "~/additionalModels/AnybillResponse";
import { useTypedFetch } from "~/composables/useTypedFetch";
import type AddressDto from "~/models/AddressDto";
import BillingTypeEnum from "~/models/BillingTypeEnum";
import DistributorCustomerUserInfoDto from "~/models/DistributorCustomerUserInfoDto";
import type InvoiceInfoDto from "~/models/InvoiceInfoDto";
import type InvoiceOverviewDto from "~/models/InvoiceOverviewDto";
import MiscellaneousCustomerUserInfoDto from "~/models/MiscellaneousCustomerUserInfoDto";
import PosSoftwareCustomerUserInfoDto from "~/models/PosSoftwareCustomerUserInfoDto";
import type StandardPricingDetailsDto from "~/models/StandardPricingDetailsDto";
import VendorCustomerOnboardingStateEnum from "~/models/VendorCustomerOnboardingStateEnum";
import VendorCustomerStateEnum from "~/models/VendorCustomerStateEnum";
import VendorCustomerUserInfoDto from "~/models/VendorCustomerUserInfoDto";
import VendorDto from "~/models/VendorDto";
import AccessGroupCustomerEnum from "~/models/AccessGroupCustomerEnum";
import PermissionEnum from "~/models/PermissionEnum";
import type InvoiceContractInfoDto from "~/models/InvoiceContractInfoDto";
import type UserInfoDto from "~/models/UserInfoDto";
import BaseService from "~/services/BaseService";
import EndpointPaths from "~/config/constants/EndpointPaths";
import ContactPersonDto from "~/models/ContactPersonDto";

export const useUserModule = defineStore("user", () => {
  const customertype = ref<string | null>(null);
  const storeModule = useStoreModule();
  const info = ref<InvoiceOverviewDto | null>(null);
  const invoices = ref<InvoiceInfoDto[] | null>(null);
  const userBillingAddress = ref<AddressDto | null>(null);
  const userAddress = ref<AddressDto | null>(null);
  const vendor = ref<VendorDto>(new VendorDto());
  const loading = ref(false);
  const vatId = ref<string | null>(null);
  const user = ref<
    | VendorCustomerUserInfoDto
    | PosSoftwareCustomerUserInfoDto
    | DistributorCustomerUserInfoDto
    | MiscellaneousCustomerUserInfoDto
    | null
  >(null);
  const userRights = ref<Map<string, number> | null>(new Map());

  const initialized = computed(() => user.value !== null);

  const asVendorCustomer = computed(
    () => user.value as VendorCustomerUserInfoDto,
  );

  const isParentVendorCustomer = computed(() => {
    let isParent = false;
    if ((user.value as VendorCustomerUserInfoDto)?.linkedChildVendorCustomers?.length === undefined) {
      isParent = false;
      return isParent;
    }
    isParent = (user.value as VendorCustomerUserInfoDto)?.linkedChildVendorCustomers?.length !== 0;
    return isParent;
  });

  const isChildVendorCustomer = computed(() => {
    return (user.value as VendorCustomerUserInfoDto)?.parentVendorCustomerId !== null;
  });

  const isVendorCustomer = computed(
    () => customertype.value === "vendorCustomer",
  );

  const asPOSCustomer = computed(
    () => user.value as PosSoftwareCustomerUserInfoDto,
  );

  const isPOSCustomer = computed(

    () => {
      return customertype.value === "posSoftwareCustomer";
    },

  );

  const asDistributorCustomer = computed(
    () => user.value as DistributorCustomerUserInfoDto,
  );

  const isDistributorCustomer = computed(
    () => customertype.value === "distributorCustomer",
  );

  const isMiscellaneousCustomer = computed(
    () => customertype.value === "miscellaneousCustomer",
  );

  const isVendorBilling = computed(
    () => asVendorCustomer.value.billingType === BillingTypeEnum.VendorBilling,
  );
  const isPosBilling = computed(
    () => asVendorCustomer.value.billingType === BillingTypeEnum.PosBilling,
  );

  const isCompanyProfileComplete = computed(
    () =>
      !!asVendorCustomer.value.vendorCustomer.name
      && !!asVendorCustomer.value.invoiceEmail,
  );

  const isAddressComplete = computed(() => {
    /* if (isVendorCustomer.value)
      return true; */

    return !!user.value?.billingAddress;
  });

  const isPaymentComplete = computed(() => {
    const paymentTypes = info.value?.paymentTypes;
    if (!paymentTypes)
      return false;
    return paymentTypes.some(p => p.isPreferred);
  });

  const isAllProfileComplete = computed(() => {
    if (!isVendorCustomer.value)
      return true;

    return (
      isCompanyProfileComplete.value
      && isAddressComplete.value
      && isPaymentComplete.value
    );
  });

  const isReadyForAddingPayment = computed(
    () => !!info.value?.billingAddress?.email,
  );

  const isReadyForBooking = computed(() => {
    if (!isVendorCustomer.value)
      return false;

    return storeModule.hasStores && isAllProfileComplete.value;
  });

  const isOnboarded = computed(() => {
    if (!isVendorCustomer.value)
      return true;

    return (
      asVendorCustomer.value.vendorCustomer.onboardingState
      === VendorCustomerOnboardingStateEnum.Onboarded
    );
  });

  // checks if package is booked but not activated already
  const isActivated = computed(() => {
    if (!isVendorCustomer.value)
      return true;

    return (
      asVendorCustomer.value.vendorCustomer.state
      === VendorCustomerStateEnum.Live
    );
  });

  // checks is customer are still in the pricing system V1
  const isNewPricingAvailable = computed(() => {
    if (!isVendorCustomer.value)
      return false;

    if (info.value?.pricingDetails?.type === "standard") {
      return (
        (info.value.pricingDetails as StandardPricingDetailsDto)
          .standardPricingPackage === null
      );
    }
    return info.value?.pricingDetails === null;
  });

  // activation
  const userAlreadySignedUp = ref(false);
  const userAlreadySignedUpEmail = ref("");

  function reset() {
    user.value = null;
    info.value = null;
    invoices.value = [];
    userRights.value = null;
  }

  async function populate() {
    loading.value = true;

    try {
      const overviewResp = await useTypedFetch<AnybillResult<InvoiceOverviewDto>>(
        "/invoiceOverviewService/get",
      );

      if (overviewResp.success) {
        info.value = overviewResp.value;
      }
      else {
        await AnybillLogger.instance.error(
          "Population call failed for invoice overview",
          new Error(overviewResp.errorMessage ?? "no details"),
          "user store",
        );
        return;
      }
    }
    catch (error) {
      await AnybillLogger.instance.error(
        "Population call failed for invoice overview",
        new Error(error as string),
        "user store",
      );
      return;
    }
    const invoicesResp = await useTypedFetch<AnybillResult<InvoiceContractInfoDto[]>>("/invoiceService/get?take=500");

    if (invoicesResp.success) {
      if (invoicesResp.statusCode === 200) {
        invoices.value = [];
        invoicesResp.value.forEach((contract) => {
          contract.invoicesInfo?.forEach((info) => {
            invoices.value?.push(info);
          });
        });
      }
    }
    else {
      await AnybillLogger.instance.error(
        "Population call failed for invoices",
        new Error(invoicesResp.errorMessage ?? "no details"),
        "user store",
      );
      return;
    }

    if (isPOSCustomer) {
      const vendorResp = await useTypedFetch<AnybillResult<VendorDto>>("/vendorService/get");
      if (vendorResp.success)
        setVendor(vendorResp.value);
    }
    loading.value = false;
  }

  /**
   * Update the user address and get the newly updated user
   */
  async function updateUserAddress(newAddress: AddressDto): Promise<undefined> {
    const isUpdated = await useTypedFetch<AnybillResult<any>>("/userSelfAddressService/put", newAddress);

    if (!isUpdated.success) {
      await AnybillLogger.instance.error(
        "Update call failed for updating user address",
        new Error(isUpdated.errorMessage ?? "no details"),
        "user store",
      );
      return;
    }

    await populate();

    const userResp = await useTypedFetch<AnybillResult<UserInfoDto>>("/userSelfService/get");

    if (userResp.success) {
      setUser(userResp.value);
    }
    else {
      await AnybillLogger.instance.error(
        "Population call failed after updating user address",
        new Error(userResp.errorMessage ?? "no details"),
        "user store",
      );
    }
  }

  async function setNewBillingAddress(address: AddressDto) {
    userAddress.value = address;
  }

  async function getUserInfo(): Promise<VendorCustomerUserInfoDto
  | PosSoftwareCustomerUserInfoDto
  | DistributorCustomerUserInfoDto
  | MiscellaneousCustomerUserInfoDto | null> {
    const userInfoResponse = await BaseService.Instance.get<
      VendorCustomerUserInfoDto
      | PosSoftwareCustomerUserInfoDto
      | DistributorCustomerUserInfoDto
      | MiscellaneousCustomerUserInfoDto
    >({
      path: EndpointPaths.GET_USER_INFO_PATH,
    });

    if (userInfoResponse.success)
      return userInfoResponse.value;

    return null;
  }

  function setUser(newUser: UserInfoDto) {
    newUser = ModelKeyConverter.keyToLowerCamel(newUser);
    customertype.value = newUser.type;
    switch (newUser.type) {
      case "vendorCustomer":
        newUser = new VendorCustomerUserInfoDto(newUser as VendorCustomerUserInfoDto);
        vatId.value = (newUser as VendorCustomerUserInfoDto).vatId;
        break;
      case "posSoftwareCustomer":
        newUser = new PosSoftwareCustomerUserInfoDto(newUser);
        break;
      case "distributorCustomer":
        newUser = new DistributorCustomerUserInfoDto(newUser);
        break;
      case "miscellaneousCustomer":
        newUser = new MiscellaneousCustomerUserInfoDto(newUser);
        break;
    }

    user.value = newUser;
    setVendor(newUser);
    setUserRights(newUser);
    setAddress(newUser);
  }

  function setVendor(newUser: any) {
    vendor.value = newUser.vendor;
  }

  function setAddress(newUser: any) {
    if (newUser.billingAddress)
      userBillingAddress.value = newUser.billingAddress;
    if (newUser.address)
      userAddress.value = newUser.address;
  }

  function setUserRights(newUser: any) {
    if (newUser) {
      const clone = useCloneDeep(userRights.value);
      for (const accessGroup in AccessGroupCustomerEnum) {
        clone!.set(
          accessGroup,
          Object.keys(PermissionEnum).indexOf(
            newUser.effectivePermissions.find(
              (perm: any) =>
                perm.accessGroup
                === AccessGroupCustomerEnum[accessGroup as keyof typeof AccessGroupCustomerEnum],
            )?.permission ?? 0,
          ),
        );
      }
      userRights.value = clone;
    }
    else {
      userRights.value = new Map();
    }
  }

  return {
    customertype,
    info,
    invoices,
    user,
    vendor,
    loading,
    userBillingAddress,
    userAddress,
    userRights,
    initialized,
    asVendorCustomer,
    isVendorCustomer,
    asPOSCustomer,
    isPOSCustomer,
    asDistributorCustomer,
    isDistributorCustomer,
    isMiscellaneousCustomer,
    isVendorBilling,
    isPosBilling,
    isParentVendorCustomer,
    isChildVendorCustomer,
    isCompanyProfileComplete,
    isAddressComplete,
    isPaymentComplete,
    isAllProfileComplete,
    isReadyForAddingPayment,
    isReadyForBooking,
    isOnboarded,
    isActivated,
    isNewPricingAvailable,
    userAlreadySignedUp,
    userAlreadySignedUpEmail,
    vatId,
    reset,
    populate,
    updateUserAddress,
    setNewBillingAddress,
    setUser,
    getUserInfo,
  };
});
